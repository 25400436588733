import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(brand_id) {
		const url = API_ROUTES.service.get
			.replace(':brand_id', brand_id)
		const { data } = await useJwt.get(url)

		return data
	},
	async find(id) {
		const { data } = await useJwt.get(`${API_ROUTES.service.get}/${id}`)

		return data
	},
	async save(brand_id, id, data) {
		const url = API_ROUTES.service.save
			.replace(':brand_id', brand_id)
			.replace(':id', id)
		return useJwt.put(url, data)
	},
	delete(brand_id, id) {
		const url = API_ROUTES.service.delete
			.replace(':brand_id', brand_id)
			.replace(':id', id)
		return useJwt.delete(url)
	},
	update(brand_id, id, data) {
		const url = API_ROUTES.service.save
			.replace(':brand_id', brand_id)
			.replace(':id', id)
		return useJwt.patch(url, data)
	},
}
